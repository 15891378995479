import { type RefLabel, appRefLabels } from "@/domain/reflabel/constants"
import { useGetRefLabels } from "@/networking/api"
import { createContext, useContext } from "react"

type AppRefLabel = (typeof appRefLabels)[number]

interface IRefLabels {
  reflabels: RefLabel[]
  getAppRefLabel: (code: AppRefLabel) => RefLabel | null
}

export const refLabelsContext = createContext<IRefLabels>({
  reflabels: [],
  getAppRefLabel: () => null,
})

export function AppRefLabelsContext(props: React.PropsWithChildren) {
  const { data } = useGetRefLabels({
    "code[]": [...appRefLabels],
  })

  const reflabels = (data?.["hydra:member"] ?? []) as RefLabel[]

  const getAppRefLabel = (code: AppRefLabel) => {
    const reflabels = data?.["hydra:member"] ?? []
    for (const reflabel of reflabels) {
      if (reflabel?.code === code) return reflabel as RefLabel
    }

    return null
  }

  return (
    <refLabelsContext.Provider value={{ reflabels, getAppRefLabel }}>
      {props.children}
    </refLabelsContext.Provider>
  )
}

export const useRefLabelsContext = () => useContext(refLabelsContext)

import { Box, Flex } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

import { AngleRightIcon } from "../Icons"
import { P2B } from "../Typographies"

type Props = React.PropsWithChildren<{
  to: string
  exact?: boolean
}>

export const VerticalNavTab: React.FC<Props> = ({ children, to, exact }) => (
  <NavLink to={to} end={exact}>
    {({ isActive }) => (
      <Box
        pl={5}
        pr={2}
        pt={4}
        color={isActive ? "primary.500" : "grey.600"}
        borderLeft="4px solid"
        borderLeftColor={isActive ? "primary.500" : "transparent"}
        backgroundColor={isActive ? "grey.100" : "inherit"}
        transitionProperty="color background-color border-left-color"
        transitionDuration={isActive ? "200ms" : "150ms"}
        transitionTimingFunction={isActive ? "ease-in-out" : "ease-out"}
        _hover={{ color: "primary.500" }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <P2B>{children}</P2B> <AngleRightIcon boxSize={6} />
        </Flex>

        <Box mt={4} w="full" h="1px" borderBottom="1px solid" borderBottomColor="grey.200" />
      </Box>
    )}
  </NavLink>
)

import { lazy } from "react"
import { AUTH_PATHS, PATHS } from "./paths"

const Login = lazy(() => import("@/pages/Auth/Login"))
const RequestPassword = lazy(() => import("@/pages/Auth/RequestPassword"))
const RenewPassword = lazy(() => import("@/pages/Auth/RenewPassword"))

export interface PublicRoute {
  path: string
  component: React.LazyExoticComponent<() => JSX.Element>
  suspense?: boolean
}

export const publicRoutes: PublicRoute[] = [
  {
    path: PATHS.AUTH + AUTH_PATHS.LOGIN,
    component: Login,
    suspense: false,
  },
  {
    path: PATHS.AUTH + AUTH_PATHS.REQUEST_PWD,
    component: RequestPassword,
    suspense: true,
  },
  {
    path: PATHS.AUTH + AUTH_PATHS.RENEW_PWD,
    component: RenewPassword,
    suspense: true,
  },
]

export const isPublicRoute = (pathname: string): boolean => {
  return publicRoutes.some(route => route.path === pathname)
}

import { H3 } from "@/components/Typographies"
import { VerticalNavTab, VerticalNavTabs } from "@/components/VerticalNavTabs"
import { PATHS, RESOURCES_PATHS } from "@/routing/paths"
import { Box, Grid, Stack } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"

export const Resources: React.FC = () => {
  return (
    <Grid gridTemplateColumns="300px 1fr" backgroundColor="white" h="full">
      <Stack justifyContent="space-between">
        <Box>
          <H3 pt={10} pb={6} px={6}>
            <FormattedMessage id="resources" />
          </H3>
          <VerticalNavTabs>
            <VerticalNavTab exact={false} to={`${PATHS.RESOURCES}${RESOURCES_PATHS.DOCUMENTS}`}>
              <FormattedMessage id="resources.documents" />
            </VerticalNavTab>
            <VerticalNavTab exact={false} to={`${PATHS.RESOURCES}${RESOURCES_PATHS.MAIL_SIGN}`}>
              <FormattedMessage id="resources.mail_sign" />
            </VerticalNavTab>
          </VerticalNavTabs>
        </Box>
      </Stack>

      <Box p={10} backgroundColor="grey.100" h="full" overflow="auto">
        <Routes>
          <Route
            index
            element={<Navigate to={`${PATHS.RESOURCES}${RESOURCES_PATHS.DOCUMENTS}`} replace />}
          />
          <Route path={RESOURCES_PATHS.DOCUMENTS} element={<>-Documents-</>} />
          <Route path={RESOURCES_PATHS.MAIL_SIGN} element={<>-Mail sign-</>} />
        </Routes>
      </Box>
    </Grid>
  )
}

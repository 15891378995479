import { Box, Divider, Flex, Image } from "@chakra-ui/react"
import { useIntl } from "react-intl"

import barnesLogo from "@/assets/images/barnes-logo-white.png"
import { useAuthenticatedUserContext } from "@/context/AuthenticatedUserContext"
import { PATHS } from "@/routing/paths"

import {
  BooksIcon,
  BuildingIcon,
  ChannelIcon,
  QuestionCircleIcon,
  SettingIcon,
  SlidersVAltIcon,
  TachometerFastIcon,
  TreeIcon,
  UserSquareIcon,
} from "../Icons"
import MenuItem from "./MenuItem"

export default function Menu() {
  const intl = useIntl()
  const { user } = useAuthenticatedUserContext()

  return (
    <Flex
      flexDir="column"
      justify="space-between"
      align="center"
      py={6}
      bg="primary.500"
      w="24"
      flexShrink={0}
    >
      <Image src={barnesLogo} alt="Barnes logo" h="16" w="16" />
      <Flex flexDir="column" alignItems="center" justifyContent="center" gap="6" width="100%">
        <MenuItem
          icon={TachometerFastIcon}
          path={PATHS.DASHBOARD}
          exact
          label={intl.formatMessage({ id: "menu.dashboard" })}
        />
        <MenuItem
          icon={UserSquareIcon}
          path={PATHS.CUSTOMERS}
          label={intl.formatMessage({ id: "menu.clients" })}
        />
        <MenuItem
          icon={BuildingIcon}
          path={PATHS.PROPERTIES}
          label={intl.formatMessage({ id: "menu.properties" })}
        />
        <MenuItem
          icon={TreeIcon}
          path={PATHS.NETWORK}
          label={intl.formatMessage({ id: "menu.network" })}
        />
        <MenuItem
          icon={ChannelIcon}
          path={PATHS.INTER_BU}
          label={intl.formatMessage({ id: "menu.inter-bu" })}
        />
        <MenuItem
          icon={BooksIcon}
          path={PATHS.RESOURCES}
          label={intl.formatMessage({ id: "menu.resources" })}
        />
        <MenuItem
          icon={QuestionCircleIcon}
          path={PATHS.HELP}
          label={intl.formatMessage({ id: "menu.help" })}
        />
        {user?.isGuru && (
          <>
            <MenuItem
              icon={SlidersVAltIcon}
              path={PATHS.ADMIN}
              label={intl.formatMessage({ id: "menu.admin" })}
            />
            {/**
            <MenuItem
              icon={SlidersVAltIcon}
              path={PATHS.TADMIN}
              label={intl.formatMessage({ id: "menu.tadmin" })}
            />
            */}
          </>
        )}
      </Flex>
      <Flex flexDir="column" align="center" gap="6" w="100%">
        <Box w="100%" px="2">
          <Divider color="white" />
        </Box>
        <MenuItem
          icon={SettingIcon}
          path={PATHS.PARAMETERS}
          label={intl.formatMessage({ id: "menu.parameters" })}
        />
      </Flex>
    </Flex>
  )
}

import { FullLoader } from "@/components/FullLoader"
import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { publicRoutes } from "./publicRoutesMap"

export const PublicRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map(({ path, component: Component, suspense }) => {
        const element = suspense ? (
          <Suspense fallback={<FullLoader />}>
            <Component />
          </Suspense>
        ) : (
          <Component />
        )

        return <Route key={path} path={path} element={element} />
      })}
    </Routes>
  )
}

import type { RefLabelJsonld } from "@/networking/api"

export type RefLabel = unknown &
  Pick<
    RefLabelJsonld,
    | "id"
    | "canEdit"
    | "code"
    | "createdAt"
    | "updatedAt"
    | "label"
    | "order"
    | "translations"
    | "value"
  >

export const appRefLabelsStatus = ["archive", "active", "inactive", "in-creation"]
export type AppRefLabelStatus = (typeof appRefLabelsStatus)[number]
export const appRefLabelsUsage = [
  "background",
  "agency_picture",
  "cluster_picture",
  "document",
  "drone_video",
  "external_link",
  "header",
  "id_photo",
  "iframe",
  "logo",
  "office",
  "panorama",
  "picture",
  "platform_video",
  "preview",
  "property_picture",
  "video",
] as const
export type AppRefLabelUsage = (typeof appRefLabelsUsage)[number]
export const appRefLabels = [...appRefLabelsUsage, ...appRefLabelsStatus, "no-label"]
export type AppRefLabel = (typeof appRefLabels)[number]
